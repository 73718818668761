import { useParams } from 'react-router-dom';
import { FloatingLabel } from '../../../components/FloatingLabel/FloatingLabel';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { getCoopseUrl, regex } from '../../../common/utils';
import { Loader } from '../../../components/Loader/Loader';
import { useMutation } from '@tanstack/react-query';
import { ApiException } from '../../../common/api/api.types';
import { accountApi } from '../../../common/api/api.accounts';
import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';
import useLoginContext from '../../../hooks/useLoginContext';
import { isUserAuthenticatedWithBankid } from '../../../core/authState.utils';
import InformationBox from '../../../components/InformationBox/InformationBox';
import dispatchEvent from '../../../lib/dispatchEvent';

interface VerifyEmailForm {
    verificationCode: string;
}
const VerifyNewEmail = () => {
    const navigate = useLoginNavigate();
    const context = useLoginContext();
    const { email } = useParams();
    const { register, handleSubmit, formState } = useForm<VerifyEmailForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const [disabledSendAgainButton, setDisabledSendAgainButton] = useState(true);

    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.sendChangeEmailVerificationCode,
    });

    const changeEmailMutation = useMutation<
        void,
        ApiException,
        { verificationCode: string; email: string }
    >({
        mutationFn: accountApi.changeEmailWithVerificationCode,
        onSuccess: () => {
            dispatchEvent('change-email-verification', true);
        },
        onError: (error) => {
            if (error.code === 'NO_EXTERNAL_SIGNIN') {
                context.refetchAuthState();
                navigate(SpaRoutes.ChangeEmail.Start);
            }
        },
    });

    useEffect(() => {
        let handle: NodeJS.Timeout;
        if (disabledSendAgainButton) {
            handle = setTimeout(() => setDisabledSendAgainButton(false), 10000);
        }
        return () => clearTimeout(handle);
    }, [disabledSendAgainButton]);

    const handleVerifyFormSubmit = (formData: VerifyEmailForm) => {
        if (formState.isValid && email) {
            changeEmailMutation.mutate({
                email: email,
                verificationCode: formData.verificationCode,
            });
        }
    };
    const isLoading = sendVerificationMailMutation.isPending || changeEmailMutation.isPending;

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
            changeEmailMutation.reset();
        }
    };
    const hasMedmeraId = !!context.authState.currentUser?.medmeraId;
    if (changeEmailMutation.isSuccess) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">E-postadress ändrad!</h1>

                {hasMedmeraId ? (
                    <p className="u-textCenter">
                        Din e-postadress är verifierad och din
                        <br />
                        e-postadress för inloggning och medlemskap har ändrats!
                    </p>
                ) : (
                    <p className="u-textCenter">
                        Din e-postadress är verifierad och din
                        <br />
                        e-postadress för inloggning har ändrats!
                    </p>
                )}

                <footer className="u-marginTauto">
                    {!isUserAuthenticatedWithBankid(context.authState) && (
                        <InformationBox
                            text={
                                'Glöm inte att använda din nya e-postadress nästa gång du loggar in!'
                            }
                        />
                    )}
                    <button
                        className="Button Button--green u-marginTmd"
                        onClick={() => {
                            window.location.href = context.authState.redirectUrl || getCoopseUrl();
                        }}
                    >
                        Klar
                    </button>
                </footer>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                Bekräfta ny
                <br />
                e-postadress
            </h1>
            <p className="u-textCenter">
                Ange verifikationskoden som vi skickat till dig på e-postadressen{' '}
                <strong>{email}</strong>
            </p>
            <form className="Form" onSubmit={handleSubmit(handleVerifyFormSubmit)}>
                <FloatingLabel
                    id="verificationCode"
                    className="u-marginTlg"
                    label="Verifikationskod"
                    type="number"
                    autoFocus={true}
                    autoComplete="off"
                    formState={formState}
                    {...register('verificationCode', {
                        required: 'Ange verifikationskod',
                        pattern: {
                            value: regex.verificationCode,
                            message: 'Ange verifikationskod',
                        },
                    })}
                />
                <footer className="u-marginTauto">
                    {isLoading && <Loader className="u-marginTmd" />}
                    {changeEmailMutation.isError && (
                        <p className="Notice Notice--red">
                            {changeEmailMutation.error.friendlyMessage}
                        </p>
                    )}
                    {sendVerificationMailMutation.isError && (
                        <p className="Notice Notice--red">
                            {sendVerificationMailMutation.error.friendlyMessage}
                        </p>
                    )}
                    <button
                        className="Button Button--green u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading}
                    >
                        Verifiera
                    </button>
                    <button
                        className="Button Button--invertedGreenDark u-marginTmd"
                        type="button"
                        disabled={disabledSendAgainButton || isLoading}
                        onClick={sendEmailVerificationClick}
                    >
                        Skicka igen
                    </button>
                </footer>
            </form>
        </>
    );
};

export default VerifyNewEmail;
