import { Navigate, Route, Routes } from 'react-router-dom';
import ConfirmBankId from './bankid/ConfirmBankid';
import ConfirmPassword from './password/ConfirmPassword';
import { SpaRoutes } from '../../hooks/useLoginNavigate';

const Confirm = () => {
    return (
        <Routes>
            <Route path="bankid" element={<ConfirmBankId />} />
            <Route path="losenord" element={<ConfirmPassword />} />
            <Route
                path="*"
                element={
                    <Navigate
                        to={{
                            pathname: SpaRoutes.Login.Start,
                            search: location.search,
                        }}
                    />
                }
            />
        </Routes>
    );
};
export default Confirm;
