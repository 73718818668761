import { useForm } from 'react-hook-form';
import { FloatingLabel } from '../../../components/FloatingLabel/FloatingLabel';
import { regex } from '../../../common/utils';
import { useParams } from 'react-router-dom';
interface ConfirmPasswordForm {
    Email: string;
    Password: string;
}
const ConfirmPassword = () => {
    const { login_hint } = useParams();
    const { register, formState, getValues, watch, setError } = useForm<ConfirmPasswordForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    return (
        <div>
            <h1>Bekräfta lösenord</h1>
            <p>Den här funktionen stöds ej längre.</p>
        </div>
    );
};

export default ConfirmPassword;
