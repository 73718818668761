interface Space {
    /**
     * Space with 4px
     */
    xxSmall: string;

    /**
     * Space with 8px
     */
    xSmall: string;

    /**
     * Space with 16px
     */
    small: string;

    /**
     * Space with 20px
     */
    medium: string;

    /**
     * Space with 24px
     */
    large: string;

    /**
     * Space with 28px
     */
    xLarge: string;

    /**
     * Space with 40px
     */
    xxLarge: string;

    /**
     * Default mobile padding of 14px
     */
    paddingSmall: string;

    /**
     * Default padding of 28px
     */
    padding: string;
}

interface FontSize {
    /**
     * Font size of 12px
     */
    xSmall: string;

    /**
     * Font size of 14px
     */
    small: string;

    /**
     * Font size of 16px
     */
    medium: string;

    /**
     * Font size of 18px
     */
    large: string;

    /**
     * Font size of 24px
     */
    xLarge: string;

    /**
     * Font size of 80px
     */
    gigantic: string;
}

interface Dimension {
    /**
     * Container width of 500px
     */
    containerSmall: string;

    /**
     * Container width of 980px
     */
    containerMedium: string;

    /**
     * Container width of 1280px
     */
    containerLarge: string;

    /**
     * Header height of 80px
     */
    headerHeight: string;
}

interface Breakpoint {
    /**
     * Breakpoint width of min 600px
     */
    small: string;

    /**
     * Breakpoint width of min 769px
     */
    medium: string;

    /**
     * Breakpoint width of min 1025px
     */
    large: string;
}

export const space: Space = {
    xxSmall: "4px",
    xSmall: "8px",
    small: "16px",
    medium: "20px",
    large: "24px",
    xLarge: "28px",
    xxLarge: "40px",

    paddingSmall: "14px",
    padding: "28px",
};

export const fontSize: FontSize = {
    xSmall: "12px",
    small: "14px",
    medium: "16px",
    large: "18px",
    xLarge: "24px",
    gigantic: "80px",
};

export const dimension: Dimension = {
    containerSmall: "500px",
    containerMedium: "980px",
    containerLarge: "1280px",
    headerHeight: "80px",
};

const size = {
    small: "600px",
    medium: "769px",
    large: "1025px",
};

export const breakpoint: Breakpoint = {
    small: `(min-width: ${size.small})`,
    medium: `(min-width: ${size.medium})`,
    large: `(min-width: ${size.large})`,
};
