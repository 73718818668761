import { useForm } from 'react-hook-form';
import '../../../../../../components/Form/Form.css';
import { useMutation } from '@tanstack/react-query';
import { ApiException } from '../../../../../../common/api/api.types';
import { accountApi } from '../../../../../../common/api/api.accounts';
import { regex } from '../../../../../../common/utils';
import { FloatingLabel } from '../../../../../../components/FloatingLabel/FloatingLabel';
import { Loader } from '../../../../../../components/Loader/Loader';
import useExternalUser from '../../../../useExternalUser';
import useLoginNavigate, { SpaRoutes } from '../../../../../../hooks/useLoginNavigate';
import useLoginContext from '../../../../../../hooks/useLoginContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
interface FormData {
    verificationCode: string;
}
const VerifyNewEmail = () => {
    const context = useLoginContext();
    const [disabledSendAgainButton, setDisabledSendAgainButton] = useState(true);
    let { email } = useParams();
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        let handle: NodeJS.Timeout;
        if (disabledSendAgainButton) {
            handle = setTimeout(() => setDisabledSendAgainButton(false), 10000);
        }
        return () => clearTimeout(handle);
    }, [disabledSendAgainButton]);

    const { register, handleSubmit, formState } = useForm<FormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const navigate = useLoginNavigate();
    if (!email) {
        navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
    }
    const createEmailAccountMutation = useMutation<
        void,
        ApiException,
        { email: string; code: string }
    >({
        mutationFn: async (params) => {
            if (executeRecaptcha) {
                var recaptcha = await executeRecaptcha();
                await accountApi.createNewAccountWithBankid(params.email, params.code, recaptcha);
            }
        },
        onSuccess: () => {
            navigate(SpaRoutes.Login.RememberMe);
        },
    });

    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.createAccountSendEmailVerificationCode,
    });

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
        }
    };

    const { givenName } = useExternalUser(context.authState);
    const handleStartFormSubmit = (formData: FormData) => {
        if (formState.isValid && email) {
            createEmailAccountMutation.mutate({ email: email, code: formData.verificationCode });
        }
    };
    const isLoading =
        createEmailAccountMutation.isPending || sendVerificationMailMutation.isPending;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Ange verifikationskod</h1>
            <p className="u-textCenter">
                Ange verifikationskoden du fick i mailet skickat till <strong>{email}</strong>
            </p>
            <form className="Form" onSubmit={handleSubmit(handleStartFormSubmit)}>
                <FloatingLabel
                    id="verificationCode"
                    className="u-marginTlg"
                    label="Verifikationskod"
                    type="number"
                    autoFocus={true}
                    formState={formState}
                    {...register('verificationCode', {
                        required: 'Ange verifikationskod',
                        pattern: {
                            value: regex.verificationCode,
                            message: 'Ange verifikationskod',
                        },
                    })}
                />
                <footer className="u-marginTauto">
                    {sendVerificationMailMutation.isError && (<div className="u-marginTmd">Din session har utgått, prova börja om från början</div>)}
                    {isLoading && <Loader className="u-marginTmd" />}
                    {createEmailAccountMutation.isError && (
                        <p className="Notice Notice--red">
                            {createEmailAccountMutation.error.friendlyMessage}
                        </p>
                    )}
                    <button
                        className="Button Button--green u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading || !executeRecaptcha}
                    >
                        Verifiera
                    </button>
                    <button
                        className="Button Button--invertedGreenDark u-marginTmd"
                        type="button"
                        disabled={disabledSendAgainButton || isLoading || !executeRecaptcha}
                        onClick={sendEmailVerificationClick}
                    >
                        Skicka verifikationskoden igen
                    </button>
                </footer>
            </form>
        </>
    );
};

export { VerifyNewEmail };
