import React, { useState, forwardRef, ChangeEvent, MouseEvent, KeyboardEvent } from 'react';
import HideIcon from '../../icons/hide.svg?react';
import ShowIcon from '../../icons/show.svg?react';
import '../FloatingLabel/FloatingLabel.css';
import '../Icon/Icon.css';

interface FloatingLabelProps {
    id: string;
    className?: string;
    name: string;
    label: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: any;
    autoFocus?: boolean;
    autoComplete?: string;
    placeholder?: string;
    type?: string;
    formState?: any;
    defaultChecked?: boolean;
    defaultValue?: string;
    utils?: string;
    disabled?: boolean;
}

const FloatingLabel = forwardRef(
    (
        {
            id,
            className,
            name,
            label,
            onChange,
            onBlur,
            formState,
            type,
            utils,
            defaultChecked,
            disabled,
            ...remainingProps
        }: FloatingLabelProps,
        forwardedRef: any,
    ) => {
        const [value, setValue] = useState(remainingProps.defaultValue || '');
        const [invalid, setInvalid] = useState(false);
        const [passwordShown, setPasswordShown] = useState(false);

        const fixChromeAutofillIssueOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
            const input = event.currentTarget;
            const value = input.value;

            if (value) {
                input.value = value;
            }
        };

        const handleClearInput = (
            e: MouseEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
        ) => {
            if (formState.errors[name]) {
                setValue('');
            }
        };

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e);
            setValue(e.target.value);
        };

        return (
            <>
                <div
                    className={`FloatingLabel ${className ? className : ''} ${
                        value ? 'has-value is-typing' : ''
                    } ${invalid ? 'is-invalid' : ''} ${
                        value && !formState?.errors[name] ? 'is-valid' : ''
                    }`}
                >
                    <input
                        id={id}
                        className={utils ? utils : 'FloatingLabel-field'}
                        ref={forwardedRef}
                        name={name}
                        autoComplete={id === 'ssn' ? 'off' : 'on'}
                        value={value}
                        defaultChecked={defaultChecked}
                        disabled={!!disabled}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event)}
                        type={passwordShown ? 'text' : type}
                        {...remainingProps}
                        onBlur={(event) => {
                            onBlur && onBlur(event);
                            setInvalid(formState?.errors[name]);
                        }}
                        onFocus={() => setInvalid(formState?.errors[name])}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                            fixChromeAutofillIssueOnClick(e)
                        }
                    />
                    {type === 'password' && value ? (
                        <button
                            type="button"
                            className="FloatingLabel-togglePasswordButton"
                            onClick={() => setPasswordShown(!passwordShown)}
                            title="Visa/dölj lösenord"
                        >
                            {passwordShown ? (
                                <HideIcon className="Icon Icon--strokeGreenHoverGreenDark" />
                            ) : (
                                <ShowIcon className="Icon Icon--strokeGreenHoverGreenDark" />
                            )}
                        </button>
                    ) : null}
                    <label
                        className={
                            type !== 'radio' ? 'FloatingLabel-label' : `FloatingLabel-${type}`
                        }
                        htmlFor={id}
                    >
                        {label}
                    </label>
                    <div
                        className="FloatingLabel-validationIcon"
                        onClick={(e: MouseEvent<HTMLInputElement>) => handleClearInput(e)}
                        role="button"
                        aria-label="clear"
                        tabIndex={0}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter') {
                                handleClearInput(e);
                            }
                        }}
                    />
                </div>
                {invalid && (
                    <div>
                        <p className="FloatingLabel-validationMessage" data-valmsg-for={id}>
                            {formState.errors[name]?.message}
                        </p>
                    </div>
                )}
            </>
        );
    },
);

export { FloatingLabel };
