/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-return-assign */
/* eslint-disable react/state-in-constructor */
import React from "react";
import Button from "../Button/Button";

import { Input, SearchButton, Wrapper } from "./style";

interface Props {
    onSearch: (query: string) => void;
    onFocus?: () => void;
    autoComplete?: boolean;
    placeholder?: string;
    hideButton?: boolean;
    outline?: boolean;
}

interface State {
    query: string;
}

class Search extends React.Component<Props, State> {
    state = {
        query: "",
    };

    searchQuery = () => {
        this.props.onSearch(this.state.query);
    };

    clearQuery = () => {
        this.setState({ query: "" });
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ query: event.target.value }, () => {
            if (this.props.autoComplete) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                this.searchQuery();
            }
        });
    };

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.keyCode) {
            case 13: // Enter
                this.searchQuery();
                break;
            case 27: // Esc
                this.clearQuery();
                break;
            default:
                break;
        }
    };

    render() {
        const { outline, hideButton } = this.props;
        const { query } = this.state;

        return (
            <Wrapper outline={outline}>
                <>
                    <Input
                        placeholder="Sök på enhetens namn, GLN eller adress"
                        onBlur={(e) =>
                            (e.target.placeholder =
                                "Sök på enhetens namn, GLN eller adress")
                        }
                        autoFocus={false}
                        value={query}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onKeyDown={this.onKeyDown}
                        onChange={this.onChange}
                    />
                </>
                {!hideButton && (
                    <SearchButton query={query}>
                        <Button
                            theme="inverted"
                            onClick={this.searchQuery}
                            title="Sök"
                        />
                    </SearchButton>
                )}
            </Wrapper>
        );
    }
}

export default Search;
