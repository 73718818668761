import { Navigate, Route, Routes } from 'react-router-dom';
import { SpaRoutes } from '../../../hooks/useLoginNavigate';
import CreateEmailAccount from './e-post/CreateEmailAccount';
import { VerifyEmail } from './e-post/verifiera-epost/Verifymail';

export const CreateNewAccountCompany = () => {
    return (
        <Routes>
            <Route path="/e-post/verifiera" element={<VerifyEmail />} />
            <Route path={'/e-post/*'} element={<CreateEmailAccount />} />
            <Route
                path={'/*'}
                element={
                    <Navigate
                        to={{
                            pathname: SpaRoutes.CreateAccount.Company.Start,
                            search: location.search,
                        }}
                    />
                }
            />
        </Routes>
    );
};
