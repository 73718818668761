import { Navigate, Route, Routes } from 'react-router-dom';
import { RadioSwitch } from '../../components/RadioSwitch/RadioSwitch';
import useLoginNavigate, { SpaRoutes } from '../../hooks/useLoginNavigate';
import { CreateNewAccountPrivate } from './privat/CreateNewAccountPrivate';
import { CreateNewAccountCompany } from './foretag/CreateNewAccountCompany';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const headerRoutes = [
    SpaRoutes.CreateAccount.Company.Start,
    SpaRoutes.CreateAccount.Private.BankId.Start,
    SpaRoutes.CreateAccount.Private.Email.Start,
];

const CreateNewAccount = () => {
    const navigate = useLoginNavigate();
    const setAccountTypeFromRadio = (e?: any) => {
        if (e?.target?.value) {
            if (e.target.value === 'Private') {
                navigate(SpaRoutes.CreateAccount.Private.BankId.Start);
            } else {
                navigate(SpaRoutes.CreateAccount.Company.Start);
            }
        }
    };
    const isCompanyRoute = location.pathname.startsWith(SpaRoutes.CreateAccount.Company.Start);
    // lets trim ending slash
    const path = location.pathname.replace(/\/$/, '');
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Le8ZqEaAAAAAC-7NuPfu7cfOWWQA8_iwp6qzg1d">
            {headerRoutes.includes(path) && (
                <RadioSwitch
                    name="PrivateOrCompany"
                    className="u-marginBmd"
                    labels={['Privat', 'Företag']}
                    values={['Private', 'Company']}
                    id="accountType"
                    defaultValue={isCompanyRoute ? 'Company' : 'Private'}
                    onChange={setAccountTypeFromRadio}
                />
            )}
            <Routes>
                <Route path={'/privat/*'} element={<CreateNewAccountPrivate />} />
                <Route path={'/foretag/*'} element={<CreateNewAccountCompany />} />
                <Route
                    path={'/*'}
                    element={
                        <Navigate
                            to={{
                                pathname: SpaRoutes.CreateAccount.Private.BankId.Start,
                                search: location.search,
                            }}
                        />
                    }
                />
            </Routes>
        </GoogleReCaptchaProvider>
    );
};

export default CreateNewAccount;
