interface Mixins {
    boxShadow: {
        wide: string;
        medium: string;
    };
}

const mixins: Mixins = {
    boxShadow: {
        wide: `0 4px 20px 0 rgba(0, 0, 0, 0.08)`,
        medium: `0 0 10px 0 rgba(0, 0, 0, 0.12)`,
    },
};

export default mixins;
