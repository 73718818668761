import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQuerySearchParams = () => {
    return new URLSearchParams(useLocation().search);
};

type QueryHistoryAction = 'push' | 'replace';

export const useQuerySearchParam = <TDefaultValue = null>(
    key: string,
    defaultValue: TDefaultValue,
) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get(key) || defaultValue;

    const navigate = useNavigate();

    const updateUrl = useCallback(
        (newVal: string | null, historyAction: QueryHistoryAction = 'push') => {
            const q = new URLSearchParams(location.search);

            if (newVal?.trim()) {
                q.set(key, newVal);
            } else {
                q.delete(key);
            }

            const newHistoryLocation = {
                search: q?.toString(),
            };

            if (historyAction === 'push') {
                navigate(newHistoryLocation);
                return;
            }

            if (historyAction === 'replace') {
                navigate(newHistoryLocation);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location, key],
    );

    return [query, updateUrl] as const;
};
