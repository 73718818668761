import { useMutation } from '@tanstack/react-query';
import { ApiException } from '../../../../common/api/api.types';
import { DotVomEmailWarning } from '../../../../components/DotVomEmailWarning/DotVomEmailWarning';
import { FloatingLabel } from '../../../../components/FloatingLabel/FloatingLabel';
import { PasswordStrengthIndicator } from '../../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import useLoginNavigate, { SpaRoutes } from '../../../../hooks/useLoginNavigate';
import useLoginContext from '../../../../hooks/useLoginContext';
import { useForm } from 'react-hook-form';
import { regex } from '../../../../common/utils';
import { accountApi } from '../../../../common/api/api.accounts';

interface FormFields {
    Email: string;
    Password: string;
    ConfirmPassword: string;
}

const CreateEmailAccount = () => {
    const context = useLoginContext();
    const navigate = useLoginNavigate();
    const { register, formState, watch, getValues } = useForm<FormFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });

    const submitFormClick = () => {
        if (formState.isValid) {
            sendVerificationMailMutation.mutate({
                email: getValues('Email'),
                password: getValues('Password'),
                confirmPassword: getValues('ConfirmPassword'),
                accountType: 'Company',
            });
        }
    };

    const sendVerificationMailMutation = useMutation<
        void,
        ApiException,
        {
            email: string;
            password: string;
            confirmPassword: string;
            accountType: 'Company';
        }
    >({
        mutationFn: async (params) => {
            await accountApi.signinExternalForNewEmailAccount({
                email: params.email,
                password: params.password,
                accountType: params.accountType,
                confirmPassword: params.confirmPassword,
            });
        },
        onSuccess: () => {
            context.refetchAuthState();
            accountApi.createAccountSendEmailVerificationCode(getValues('Email'));
            navigate(SpaRoutes.CreateAccount.Company.Email.Verify);
        },
    });

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Skapa företagskonto</h1>
            <FloatingLabel
                id="email"
                className="u-marginTlg"
                label="E-postadress"
                autoFocus={true}
                type="email"
                formState={formState}
                {...register('Email', {
                    required: 'Ange e-postadress.',
                    pattern: {
                        value: regex.email,
                        message: 'Angiven e-postadress har fel format.',
                    },
                })}
            />
            <DotVomEmailWarning value={watch('Email')} />
            <div>
                <FloatingLabel
                    id="password"
                    className="u-marginTmd"
                    label="Lösenord"
                    type="password"
                    autoComplete="off"
                    formState={formState}
                    {...register('Password', {
                        required: 'Ange lösenord.',
                        pattern: {
                            value: regex.password,
                            message: 'Lösenordet måste bestå av minst 6 tecken.',
                        },
                    })}
                />
                <FloatingLabel
                    id="confirmPassword"
                    className="u-marginTmd"
                    label="Bekräfta lösenord"
                    type="password"
                    autoComplete="off"
                    formState={formState}
                    {...register('ConfirmPassword', {
                        required: 'Ange lösenord.',
                        validate: (value) =>
                            value === getValues('Password') || 'Lösenorden matchar inte varandra.',
                    })}
                />
            </div>
            <PasswordStrengthIndicator className="u-marginTmd" value={watch('Password')} />

            <footer className="u-marginTauto">
                {sendVerificationMailMutation.isError && (
                    <p className="Notice Notice--red">
                        {sendVerificationMailMutation.error.friendlyMessage}
                    </p>
                )}
                <p className="u-textXSmall u-colorGray u-marginTmd u-textCenter">
                    Genom att skapa ett företagskonto godkänner du{' '}
                    <a
                        className="Link"
                        href="https://www.coop.se/globala-sidor/anvandarvillkor/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Coops villkor
                    </a>
                    .{' '}
                    <a
                        className="Link"
                        href="https://www.coop.se/Globala-sidor/Om-webbplatsen/personuppgifter/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Klicka här
                    </a>{' '}
                    för att läsa om hur Coop hanterar din personliga information.
                </p>
                <button
                    className="Button Button--green u-marginTmd"
                    type="submit"
                    disabled={!formState.isValid || sendVerificationMailMutation.isPending}
                    onClick={submitFormClick}
                >
                    Skapa företagskonto
                </button>
                <p className="u-textXSmall u-colorGray u-sm-hidden">
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a
                        className="Link"
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                        className="Link"
                        href="https://policies.google.com/terms"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms of Service
                    </a>{' '}
                    apply.
                </p>
            </footer>
        </>
    );
};

export default CreateEmailAccount;
