export const getCookie = (key: string) => {
    const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
};

export const regex = {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,18}$/i,
    emailSpaceAtEnd: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,18}\s*$/i,
    socialSecurityNumber:
        /^(19|20)?[0-9]{2}[- ]?((0[0-9])|(10|11|12))[- ]?(([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1])))[- ]?[0-9]{4}$/i,
    verificationCode: /^[0-9]{6}$/i,
    password: /^.{6,}$/,
};

type CoopseRedirectReason = "accountRemoved";

export const getCoopseUrl = (redirectReason?: CoopseRedirectReason) => {
    let url = "";
    if (window.location.hostname === "login.coop.se") {
        url = "https://www.coop.se/";
    } else if (window.location.hostname === "loginbeta.coop.se") {
        url = "https://beta.coop.se/";
    } else if (
        window.location.hostname === "logindev.coop.se" ||
        window.location.hostname === "logintest.coop.se"
    ) {
        url = "https://www-test1.coop.se/";
    } else if (
        window.location.hostname === "loginlocal.coop.se" ||
        window.location.hostname === "login.local"
    ) {
        url = "https://local.coop.se/";
    } else {
        url = "https://www.coop.se/";
    }

    if (redirectReason) {
        let query = new URLSearchParams();
        query.set("redirectReason", redirectReason);
        return url + "?" + query.toString();
    }

    return url;
};

export const getCoopseLoginUrl = () => {
    if (window.location.hostname === "login.coop.se") {
        return "https://www.coop.se/logga-in";
    } else if (window.location.hostname === "loginbeta.coop.se") {
        return "https://beta.coop.se/logga-in";
    } else if (
        window.location.hostname === "logindev.coop.se" ||
        window.location.hostname === "logintest.coop.se"
    ) {
        return "https://www-test1.coop.se/logga-in";
    } else if (
        window.location.hostname === "loginlocal.coop.se" ||
        window.location.hostname === "login.local"
    ) {
        return "https://local.coop.se/logga-in";
    } else {
        return "https://www.coop.se/logga-in";
    }
};

export const getSessionStorageOrDefault = (key: string, defaultValue: any) => {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    try {
        return JSON.parse(stored);
    } catch {
        return defaultValue;
    }
};
export const isIos = (): boolean => {
    const ios =
        (/iPad|iPhone|iPod/i.test(window.navigator.userAgent) ||
            (window.navigator &&
                window.navigator.platform === "MacIntel" &&
                window.navigator.maxTouchPoints > 1)) &&
        !(window as any).MSStream;
    return ios;
};

export const getBankIdUrl = (autostartToken: string) => {
    const isIos =
        (/iPad|iPhone|iPod/i.test(window.navigator.userAgent) ||
            (window.navigator &&
                window.navigator.platform === "MacIntel" &&
                window.navigator.maxTouchPoints > 1)) &&
        !(window as any).MSStream;
    const isIosFirefox = isIos && /FxiOS/i.test(window.navigator.userAgent);
    const isIosChrome = isIos && /CriOS/i.test(window.navigator.userAgent);
    const isIosEdge = isIos && /EdgiOS/i.test(window.navigator.userAgent);
    const isIosSafari =
        isIos &&
        /WebKit/i.test(window.navigator.userAgent) &&
        !isIosChrome &&
        !isIosFirefox &&
        !isIosEdge;
    let redirectURL = "null";
    const prefixPart = isIos ? "https://app.bankid.com/" : "bankid:///";

    if (isIosSafari) {
        redirectURL = window.location.href;
    } else if (isIosChrome) {
        redirectURL = "googlechromes://";
    } else if (isIosFirefox) {
        redirectURL = "firefox://";
    } else if (isIosEdge) {
        redirectURL = "null";
    }
    return (
        prefixPart +
        "?autostarttoken=" +
        autostartToken +
        "&redirect=" +
        encodeURIComponent(redirectURL)
    );
};
