import React from 'react';
import { AppContext } from '../pages/App';

const useLoginContext = () => {
    const context = React.useContext(AppContext);
    if (context === null) throw 'Invalid context';
    return context;
};

export default useLoginContext;
