// gets the current loginstate for the user

import { userStateApi } from '../common/api/api.state';
import queryKeys from '../core/queryKeys';
import { useOpenIdQuery } from './useOpenIdQuery';
import { useQuery } from '@tanstack/react-query';

const useAuthState = () => {
    const QUERY_KEY = queryKeys.authState;
    const { returnUrl, logoutId } = useOpenIdQuery();
    const query = useQuery({
        queryKey: [QUERY_KEY, returnUrl, logoutId],
        queryFn: async () => {
            const authResult = await userStateApi.getUserState(returnUrl, logoutId);
            return authResult;
        },
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });
    return query;
};

export { useAuthState };
