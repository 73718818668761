import { getCoopseLoginUrl } from '../../common/utils';

const Success = () => {
    return (
        <div>
            <p className="u-textCenter">Ditt konto har nu tagits bort från coop.se.</p>
            <p className="u-textCenter u-marginT">
                <a href={getCoopseLoginUrl()} className="Button Button--green">
                    Gå till Coop.se
                </a>
            </p>
        </div>
    );
};

export default Success;
