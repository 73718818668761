import { FC } from "react";
import "./LineLoader.css";

export interface LineLoaderProps {
    isLoading: boolean;
}
const LineLoader: FC<LineLoaderProps> = ({ isLoading }) => {
    return (
        <div className="Lineloader">
            <div
                className={`Lineloader-content${isLoading ? " is-active" : ""}`}
            >
                <div className="Lineloader-element"></div>
            </div>
        </div>
    );
};

export default LineLoader;
