import { config } from 'process';
import { AccountsDto } from './api.bankid';
import defaultAxiosInstance from './defaultAxiousClient';

const kimEmail = async () => {
    const response = await defaultAxiosInstance.get<string>('/local/email/kim-email');
    return response.data;
};

const isEmailInUse = async (email: string) => {
    const response = await defaultAxiosInstance.post<CheckEmailAvailabilityResponse>(
        '/local/email/is-in-use',
        { email: email },
    );
    return response.data.isInUse;
};

/** Used for logged in users that has a real account */
const sendChangeEmailVerificationCode = async (email: string) => {
    await defaultAxiosInstance.post('/local/change-email/send-verification-code', {
        email: email,
    });
};

const connectEmailWithBankid = async (email: string, code: string) => {
    await defaultAxiosInstance.post('/local/email/connect-email-with-bankid', {
        email: email,
        verificationCode: code,
    });
};

const createNewAccountWithBankid = async (email: string, code: string, recaptcha: string) => {
    await defaultAxiosInstance.post(
        '/local/create/new-account-with-bankid?recaptcha=' + encodeURIComponent(recaptcha),
        {
            email: email,
            verificationCode: code,
        },
    );
};

/** Used to send email to external users that hasnt an  account */
const createAccountSendEmailVerificationCode = async (email: string) => {
    await defaultAxiosInstance.post('/local/create/external-scheme/send-verification-code', {
        email: email,
    });
};

const changePasswordWithBankid = async (password: string) => {
    await defaultAxiosInstance.post('/local/change-password/bankid', {
        password,
    });
};

const changeEmailWithVerificationCode = async (params: {
    email: string;
    verificationCode: string;
}) => {
    await defaultAxiosInstance.post('/local/change-email', {
        email: params.email,
        verificationCode: params.verificationCode,
    });
};

const changePasswordWithEmail = async (params: {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}) => {
    await defaultAxiosInstance.post('/local/change-password/email', {
        oldPassword: params.oldPassword,
        password: params.password,
        confirmPassword: params.confirmPassword,
    });
};

const createNewAccountWithEmail = async (params: {
    verificationCode: string;
    recaptcha: string;
}) => {
    await defaultAxiosInstance.post(
        '/local/create/new-account-with-email?recaptcha=' + encodeURIComponent(params.recaptcha),
        {
            verificationCode: params.verificationCode,
        },
    );
};

const removeAccountWithBankid = async (params: { logoutId?: string }) =>
    (
        await defaultAxiosInstance.post<RemoveAccountResponse>(
            '/local/external/remove-user/with-bankid',
            {
                logoutId: params.logoutId,
            },
        )
    ).data.logoutId;

const removeAccountWithEmail = async (params: {
    email: string;
    password: string;
    logoutId?: string;
}) => {
    return (
        await defaultAxiosInstance.post<RemoveAccountResponse>(
            '/local/external/remove-user/with-email',
            {
                email: params.email,
                password: params.password,
                logoutId: params.logoutId,
            },
        )
    ).data.logoutId;
};

const getBankidUserAccounts = async () => {
    var response = await defaultAxiosInstance.get<AccountsDto[]>(
        '/local/create/get-bankid-user-accounts',
    );
    if (!Array.isArray(response.data)) {
        throw new Error('Invalid response');
    }
    return response.data;
};

/** Sening in loginhint will makes the signin fail if the user doensnt signin with the same userid as in the loginhint */
const signinApplicationWithExternalSchema = async (rememberMe: boolean, loginHint?: string) => {
    await defaultAxiosInstance.post('/local/signin/application-schema/with-external-schema', {
        rememberMe: rememberMe,
        loginHint,
    });
};

const signinExternalForNewEmailAccount = async (params: {
    email: string;
    password: string;
    confirmPassword: string;
    accountType: 'Private' | 'Company';
}) => {
    await defaultAxiosInstance.post('/local/signin/external-schema/with-unverified-credentials', {
        email: params.email,
        password: params.password,
        accountType: params.accountType,
    });
};

const signinWithEmailPasswordToExternalSchema = async (params: {
    email: string;
    password: string;
    accountType: 'Private' | 'Company';
}) => {
    await defaultAxiosInstance.post('/local/signin/external-schema/email-password', {
        email: params.email,
        password: params.password,
        accountType: params.accountType,
    });
};

const signinWithEmailPasswordToApplicationSchema = async (params: {
    email: string;
    password: string;
    accountType: 'Private' | 'Company' | 'Both';
    rememberMe: boolean;
}) => {
    await defaultAxiosInstance.post('/local/signin/application-schema/email-password', {
        email: params.email,
        password: params.password,
        accountType: params.accountType,
        rememberMe: params.rememberMe,
    });
};
const sendForgotPasswordEmail = async (params: { email: string }) => {
    await defaultAxiosInstance.post('/local/email/send-forgot-password', {
        email: params.email,
    });
};
const resetPassword = async (params: {
    password: string;
    confirmPassword: string;
    email: string;
    code: string;
}) => {
    await defaultAxiosInstance.post('local/reset-password', {
        email: params.email,
        password: params.password,
        confirmPassword: params.confirmPassword,
        code: params.code,
    });
};
const getIdentityProviderForEmail = async (params: { email: string }) => {
    const result = await defaultAxiosInstance.post<IdentityProviderDto | undefined>(
        '/local/get-provider/for-email',
        {
            email: params.email,
        },
        {
            validateStatus: (status) => status < 299 || status == 404,
        },
    );
    return result.data;
};
/**
 * Signout/Logout a user that is authentication with the external schema. External schema is used for temporary states, like validation of bankid/punchout/microsoft aad
 * After doing this, its prob best to do a refresh of authstate if you want to continue.
 */
const signOutExternalScheme = async () => {
    await defaultAxiosInstance.post('/local/signout-externalscheme');
};

/**
 * Signout/Logout a user that is authentication with the external schema. External schema is used for temporary states, like validation of bankid/punchout/microsoft aad
 * After doing this, its prob best to do a refresh of authstate if you want to continue.
 */
const signOutApplicationScheme = async () => {
    await defaultAxiosInstance.post('/local/signout-applicationscheme');
};

export const accountApi = {
    kimEmail,
    isEmailInUse,
    connectEmailWithBankid,
    createNewAccountWithBankid,
    createNewAccountWithEmail,
    createAccountSendEmailVerificationCode,
    signinExternalForNewEmailAccount,
    signinWithEmailPasswordToExternalSchema,
    signinWithEmailPasswordToApplicationSchema,
    signinApplicationWithExternalSchema,
    changePasswordWithBankid,
    changePasswordWithEmail,
    changeEmailWithVerificationCode,
    sendChangeEmailVerificationCode,
    signOutExternalScheme,
    signOutApplicationScheme,
    getBankidUserAccounts,
    getIdentityProviderForEmail,
    removeAccountWithBankid,
    removeAccountWithEmail,
    sendForgotPasswordEmail,
    resetPassword,
};

interface RemoveAccountResponse {
    logoutId?: string;
}
interface CheckEmailAvailabilityResponse {
    isInUse: boolean;
}

export interface VerifyEmailCodeRequest {
    email: string;
    verificationCode: string;
}
export interface IdentityProviderDto {
    scheme: string;
    domains: string[];
    name: string;
    // Errorcode in case of error
    code: string;
}
