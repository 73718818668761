import { useMutation } from '@tanstack/react-query';
import useLoginContext from '../../../hooks/useLoginContext';
import { useEffect } from 'react';
import { accountApi } from '../../../common/api/api.accounts';
import { ApiException } from '../../../common/api/api.types';
import { useBankid } from '../../../components/BankId/BankId.hooks';
import BankId from '../../../components/BankId/BankId';
import { useOpenIdQuery } from '../../../hooks/useOpenIdQuery';
import { useNavigate } from 'react-router-dom';
import { SpaRoutes } from '../../../hooks/useLoginNavigate';

const RemoveAccountWithBankid = () => {
    const context = useLoginContext();
    const authState = context.authState;
    const navigate = useNavigate();

    const { logoutId } = useOpenIdQuery();
    const deleteAccountMutation = useMutation<void, ApiException>({
        mutationFn: async () => {
            const lid = await accountApi.removeAccountWithBankid({ logoutId: logoutId });
            await accountApi.signOutExternalScheme();
            navigate({
                pathname: SpaRoutes.Logout.Start,
                search: lid
                    ? `logoutId=${encodeURIComponent(lid)}&returnUrl=${
                          SpaRoutes.RemoveAccount.Done
                      }`
                    : `returnUrl=${SpaRoutes.RemoveAccount.Done}`,
            });
        },
    });

    const onBankidSuccess = async () => {
        deleteAccountMutation.mutate();
    };
    const {
        start,
        cancel,
        isStarted,
        isCollecting,
        progress,
        message,
        errorMessage,
        autoStartToken,
        qrCode,
        isCompleted,
    } = useBankid({
        isConfirm: false,
        onSuccess: onBankidSuccess,
    });
    useEffect(() => {
        document.title = 'Coop | Radera kundkonto';
    }, []);

    if (deleteAccountMutation.isSuccess) {
        return (
            <>
                <p className="u-textCenter">
                    Ditt kundkonto
                    <br /> <strong className="u-textNoWrap">{authState?.currentUser?.email}</strong>
                    <br /> har nu tagits bort från coop.se.
                </p>
            </>
        );
    }

    if (!context.authState?.currentUser) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Ta bort kundkonto</h1>
                <p>För att ta bort ett konto behöver du vara inloggad.</p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Ta bort kundkonto</h1>

            <p>
                <strong>
                    Tänk på detta innan du tar bort ditt kundkonto till coop.se och coop-appen:
                </strong>
            </p>
            <ul>
                <li>
                    Beställningar och sparade varukorgar från e-handeln kommer inte vara
                    tillgängliga på webben via andra konton.
                </li>
                <li className="last">
                    Är du medlem kommer medlemskapet finnas kvar hos Coop och KF.
                </li>
            </ul>

            <p>
                Du är alltid välkommen att skapa ett nytt kundkonto för att handla online, eller som
                medlem ta del av alla medlemsförmåner.
            </p>

            <p>
                Vill du ta bort kundkontot <strong>{authState?.currentUser?.email}</strong>?
            </p>
            <br />
            <br />
            <h2 className="Heading Heading--small">
                Verifiera din inloggning för att ta bort kontot.
            </h2>
            <footer className="u-marginTauto">
                {deleteAccountMutation.isError && (
                    <p className="Notice Notice--red">
                        {deleteAccountMutation.error.friendlyMessage}
                    </p>
                )}
            </footer>
            {!isCompleted && (
                <BankId
                    start={start}
                    cancel={cancel}
                    disabled={deleteAccountMutation.isPending}
                    isStarted={isStarted}
                    isCollecting={isCollecting}
                    progress={progress}
                    message={message}
                    errorMessage={errorMessage}
                    autoStartToken={autoStartToken}
                    qrCode={qrCode}
                />
            )}
        </>
    );
};

export { RemoveAccountWithBankid };
