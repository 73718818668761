const queryKeys = {
    bankid: {
        collect: 'bankid.collect',
        auth: 'bankid.auth',
    },
    authState: 'user_State',
    kimEmail: 'kim_email',
};

export default queryKeys;
