import styled from "styled-components";
import colors from "../../../../common/colors";
import { fontSize, space } from "../../../../common/variables";

export const Wrapper = styled.div`
    width: 100%;
    overflow-y: auto;
`;

export const Unit = styled.div`
    font-size: ${fontSize.small};
    flex-grow: 1;
`;

export const Info = styled.div`
    font-size: ${fontSize.xSmall};
    display: flex;
    flex-wrap: wrap;
    margin-right: ${space.medium};
    gap: 0 ${space.small};
`;

export const Title = styled.p`
    margin: 0;
    text-transform: uppercase;
    padding-right: ${space.medium};
    font-size: ${fontSize.xSmall};
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const Row = styled.div<{ fillBackground: boolean | undefined }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${space.paddingSmall};
    background-color: ${(props) =>
        props.fillBackground ? colors.grayMid : colors.white};
`;
