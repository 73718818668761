import { useMutation } from '@tanstack/react-query';
import useLoginContext from '../../../hooks/useLoginContext';
import { ApiException } from '../../../common/api/api.types';
import { FloatingLabel } from '../../../components/FloatingLabel/FloatingLabel';
import { PasswordStrengthIndicator } from '../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { useForm } from 'react-hook-form';
import { getCoopseUrl, regex } from '../../../common/utils';

import { accountApi } from '../../../common/api/api.accounts';
import { useFormApiErrors } from '../../../hooks/useFormApiErrors';
import { useEffect } from 'react';
import { isApiException } from '../../../common/api/api.utils';

interface ChangePasswordFields {
    OldPassword: string;
    Password: string;
    ConfirmPassword: string;
}

const ChangePasswordEmail = () => {
    const context = useLoginContext();
    const { register, formState, getValues, watch, setError } = useForm<ChangePasswordFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const { handleErrors } = useFormApiErrors<ChangePasswordFields>(setError);

    const changePassword = async () => {
        if (formState.isValid) {
            changePasswordMutation.mutate({
                oldPassword: getValues('OldPassword'),
                password: getValues('Password'),
                confirmPassword: getValues('ConfirmPassword'),
            });
        }
    };

    const changePasswordMutation = useMutation<
        void,
        ApiException,
        { oldPassword: string; password: string; confirmPassword: string }
    >({
        mutationFn: async (params) => {
            await accountApi.changePasswordWithEmail(params);
        },
        onSuccess: async () => {
            await accountApi.signOutExternalScheme();
        },
        onError: (e) => {
            context.refetchAuthState();
        },
    });

    useEffect(() => {
        // putting handleerror inside changePasswordMutation.error wont work since the input fields are disabled.
        if (isApiException(changePasswordMutation.error)) {
            handleErrors(changePasswordMutation.error);
        }
    }, [changePasswordMutation.error]);

    const fullName = context.authState.currentUser?.firstname
        ? `${context.authState.currentUser.firstname} ${context.authState.currentUser.lastname}`
        : undefined;

    if (changePasswordMutation.isSuccess) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Ändra lösenord</h1>
                <p className="u-textCenter u-marginTlg">
                    Ditt lösenord för{' '}
                    <strong className="u-textNoWrap">{context.authState.currentUser?.email}</strong>{' '}
                    är nu ändrat.
                </p>
                <p className="u-textCenter">Glöm inte att anteckna ditt lösenord.</p>
                <p className="u-textCenter">
                    <a
                        className="Link"
                        href={context.authState.redirectUrl || getCoopseUrl()}
                        rel="noreferrer"
                    >
                        Gå vidare till Coop.se
                    </a>
                </p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Ändra lösenord</h1>

            <p className="u-textCenter">
                Du ändrar lösenordet för inloggning med e-post{' '}
                <strong>{context.authState.currentUser?.email}</strong>
            </p>
            {changePasswordMutation.isError && (
                <p className="Notice Notice--red">{changePasswordMutation.error.friendlyMessage}</p>
            )}
            <FloatingLabel
                disabled={changePasswordMutation.isPending}
                id="oldPassword"
                className="u-marginTlg"
                label="Nuvarande lösenord"
                type="password"
                autoComplete="off"
                formState={formState}
                {...register('OldPassword', {
                    required: 'Ange lösenord',
                })}
            />
            <FloatingLabel
                id="password"
                disabled={changePasswordMutation.isPending}
                className="u-marginTlg"
                label="Nytt lösenord"
                type="password"
                autoComplete="off"
                formState={formState}
                {...register('Password', {
                    required: 'Ange lösenord.',
                    pattern: {
                        value: regex.password,
                        message: 'Lösenordet måste bestå av minst 6 tecken.',
                    },
                })}
            />
            <PasswordStrengthIndicator className="u-marginTmd" value={watch('Password')} />
            <FloatingLabel
                disabled={changePasswordMutation.isPending}
                id="confirmPassword"
                className="u-marginTmd"
                label="Bekräfta nytt lösenord"
                type="password"
                autoComplete="off"
                formState={formState}
                {...register('ConfirmPassword', {
                    required: 'Ange lösenord',
                    validate: (value) =>
                        value === getValues('Password') || 'Lösenorden matchar inte varandra',
                })}
            />
            <footer className="u-marginTauto">
                <button
                    className="Button Button--ferm-green u-marginTmd"
                    disabled={
                        changePasswordMutation.isPending ||
                        changePasswordMutation.isSuccess ||
                        !formState.isValid
                    }
                    onClick={changePassword}
                >
                    Ändra lösenord
                </button>
            </footer>
        </>
    );
};

export default ChangePasswordEmail;
