import type { AuthenticationStateResult } from './api.types';
import { isApiException } from './api.utils';
import defaultAxiosInstance from './defaultAxiousClient';

const getUserState = async (returnUrl?: string, logoutId?: string) => {
    var response = await defaultAxiosInstance.get<AuthenticationStateResult>('/local/state', {
        params: {
            returnUrl: returnUrl,
            logoutId: logoutId,
        },
    });
    return response.data;
};

export const userStateApi = {
    getUserState,
};
