/* eslint-disable no-nested-ternary */
import styled, { css } from "styled-components";

import colors from "../../../../common/colors";
import { fontSize, space } from "../../../../common/variables";
import { ButtonProps } from "./Button";

export const Wrapper = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    cursor: pointer;
    &:disabled {
        opacity: 0.5;
    }
    ${(props: ButtonProps) =>
        props.size === "small"
            ? css`
                  height: 30px;
                  border-radius: 15px;
              `
            : props.size === "large"
            ? css`
                  height: 60px;
                  border-radius: 30px;
              `
            : css`
                  height: 40px;
                  border-radius: 20px;
              `};

    ${(props: ButtonProps) =>
        props.theme === "inverted"
            ? css`
                  background-color: transparent;
                  border: 1px solid ${colors.greenDark};
                  color: ${colors.greenDark};
                  fill: ${colors.greenDark};

                  &:hover {
                      background-color: ${colors.greenDark};
                      color: ${colors.white};
                      fill: ${colors.white};
                  }
              `
            : props.theme === "greenDark"
            ? css`
                  background-color: ${colors.greenDark};
                  border: 1px solid ${colors.greenDark};
                  color: ${colors.white};
                  fill: ${colors.white};

                  &:hover {
                      opacity: 0.9;
                  }
              `
            : props.theme === "redInverted"
            ? css`
                  background-color: transparent;
                  border: 1px solid ${colors.red};
                  color: ${colors.red};
                  fill: ${colors.red};

                  &:hover {
                      background-color: ${colors.red};
                      color: ${colors.white};
                      fill: ${colors.white};
                  }
              `
            : props.theme === "red"
            ? css`
                  background-color: ${colors.red};
                  border: 1px solid ${colors.red};
                  color: ${colors.white};
                  fill: ${colors.white};

                  &:hover {
                      opacity: 0.9;
                  }
              `
            : css`
                  background-color: ${colors.green};
                  border: 1px solid ${colors.green};
                  color: ${colors.white};
                  fill: ${colors.white};

                  &:hover {
                      background-color: ${colors.greenDark};
                      border: 1px solid ${colors.greenDark};
                  }
              `};

    ${(props: ButtonProps) =>
        props.shape === "round" && props.size === "small"
            ? css`
                  padding: 8px 11px 11px 11px;
                  width: 30px;
              `
            : props.shape === "round" && props.size === "large"
            ? css`
                  padding: 11px;
                  width: 60px;
              `
            : props.shape === "round"
            ? css`
                  padding: 9px 11px 11px 11px;
                  width: 40px;
              `
            : css`
                  padding: 0 ${space.xxLarge};
                  width: auto;
              `};

    ${(props: ButtonProps) =>
        props.disabled
            ? css`
    disabled;
    `
            : null};
`;

export const Text = styled.p<{ isLoading: boolean | undefined }>`
    font-size: ${fontSize.small};
    line-height: ${fontSize.small};
    margin: 0;
    /* transition: opacity 0.3s, visibility 0.3s;

    ${(props) =>
        props.isLoading
            ? `
            opacity: 0;
            visibility: hidden;
        `
            : `
            opacity: 1;
            visibility: visible;
        `}; */
`;
