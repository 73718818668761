import { useForm } from 'react-hook-form';
import '../../../../../../components/Form/Form.css';
import { useMutation } from '@tanstack/react-query';
import { ApiException } from '../../../../../../common/api/api.types';
import { accountApi } from '../../../../../../common/api/api.accounts';
import { regex } from '../../../../../../common/utils';
import { FloatingLabel } from '../../../../../../components/FloatingLabel/FloatingLabel';
import { Loader } from '../../../../../../components/Loader/Loader';
import useLoginNavigate, { SpaRoutes } from '../../../../../../hooks/useLoginNavigate';
import useLoginContext from '../../../../../../hooks/useLoginContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
interface FormData {
    verificationCode: string;
}
const VerifyEmailExistingAccount = () => {
    const context = useLoginContext();
    const [disabledSendAgainButton, setDisabledSendAgainButton] = useState(true);
    let { email } = useParams();

    useEffect(() => {
        let handle: NodeJS.Timeout;
        if (disabledSendAgainButton) {
            handle = setTimeout(() => setDisabledSendAgainButton(false), 10000);
        }
        return () => clearTimeout(handle);
    }, [disabledSendAgainButton]);

    const { register, handleSubmit, formState } = useForm<FormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const navigate = useLoginNavigate();
    if (!email) {
        navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
    }
    const verifyEmailMutation = useMutation<
        void,
        ApiException,
        Parameters<typeof accountApi.connectEmailWithBankid>
    >({
        mutationFn: (params) => accountApi.connectEmailWithBankid(params[0], params[1]),
        onSuccess: () => {
            navigate(SpaRoutes.Login.RememberMe);
        },
    });

    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.createAccountSendEmailVerificationCode,
    });

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
            verifyEmailMutation.reset();
        }
    };

    const handleStartFormSubmit = (formData: FormData) => {
        if (formState.isValid && email) {
            verifyEmailMutation.mutate([email, formData.verificationCode]);
        }
    };
    const isLoading = verifyEmailMutation.isPending || sendVerificationMailMutation.isPending;

    if (verifyEmailMutation.isError) {
        if (verifyEmailMutation.error.code === 'NO_EXTERNAL_SIGNIN') {
            return (
                <>
                    <h1 className="Heading Heading--large u-textCenter">Ange verifikationskod</h1>
                    <p className="u-textCenter">
                        Det tog för lång tid att verifiera kontot. Starta om flödet och försök på
                        nytt.
                    </p>
                    <button
                        className="Button Button--invertedGreenDark u-marginTmd"
                        type="button"
                        onClick={() => navigate(SpaRoutes.CreateAccount.Private.BankId.Start)}
                    >
                        Skapa nytt konto
                    </button>
                </>
            );
        }
    }

    return (
        <>
            <>
                <h1 className="Heading Heading--large u-textCenter">Ange verifikationskod</h1>
                <p className="u-textCenter">
                    Ange verifikationskoden du fick i mailet skickat till <strong>{email}</strong>
                </p>
            </>
            <form className="Form" onSubmit={handleSubmit(handleStartFormSubmit)}>
                <FloatingLabel
                    id="verificationCode"
                    className="u-marginTlg"
                    label="Verifikationskod"
                    type="number"
                    autoFocus={true}
                    formState={formState}
                    {...register('verificationCode', {
                        required: 'Ange verifikationskod',
                        pattern: {
                            value: regex.verificationCode,
                            message: 'Ange verifikationskod',
                        },
                    })}
                />
                <footer className="u-marginTauto">
                    {isLoading && <Loader className="u-marginTmd" />}
                    {verifyEmailMutation.isError && (
                        <p className="Notice Notice--red">
                            {verifyEmailMutation.error.friendlyMessage}
                        </p>
                    )}
                    <button
                        className="Button Button--green u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading}
                    >
                        Verifiera
                    </button>
                    <button
                        className="Button Button--invertedGreenDark u-marginTmd"
                        type="button"
                        disabled={disabledSendAgainButton || isLoading}
                        onClick={sendEmailVerificationClick}
                    >
                        Skicka verifikationskoden igen
                    </button>
                </footer>
            </form>
        </>
    );
};

export { VerifyEmailExistingAccount };
