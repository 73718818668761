import { useSearchParams } from "react-router-dom";
const RETURN_URL_KEY = "ReturnUrl";
const RETURN_URL_KEY2 = "returnUrl";
const LOGOUTID_URL_KEY = "logoutId";
// returnUrl is oauth contains the actual signin request. It contains parameters like who, what scopes, and link back to the oauth.
// cannot be used for redirecting users, its not validated!!! use the one in AuthenticationStateResult
const useOpenIdQuery = () => {
    const [searchParams] = useSearchParams();
    const returnUrl =
        searchParams.get(RETURN_URL_KEY) || searchParams.get(RETURN_URL_KEY2);
    const logoutId = searchParams.get(LOGOUTID_URL_KEY);
    return {
        returnUrl: returnUrl || undefined,
        logoutId: logoutId || undefined,
    };
};

export { useOpenIdQuery };
