import '../DotVomEmailWarning/DotVomEmailWarning.css';

interface DotVomEmailWarningProps {
	value: string;
}

const DotVomEmailWarning = (props: DotVomEmailWarningProps) => {
    return props.value && props.value.toLowerCase().endsWith(".vom") ? 
		<>
            <p className="DotVol">Du har angett ".vom", menade du ".com"?</p>
		</>
	 : null
}
export { DotVomEmailWarning };
