import { useForm } from 'react-hook-form';
import '../../../../../components/Form/Form.css';
import { useMutation } from '@tanstack/react-query';
import { ApiException, AuthenticationStateResult } from '../../../../../common/api/api.types';
import { accountApi } from '../../../../../common/api/api.accounts';
import { DotVomEmailWarning } from '../../../../../components/DotVomEmailWarning/DotVomEmailWarning';
import { regex } from '../../../../../common/utils';
import { FloatingLabel } from '../../../../../components/FloatingLabel/FloatingLabel';
import { Loader } from '../../../../../components/Loader/Loader';
import useExternalUser from '../../../useExternalUser';
import { useEffect, useState } from 'react';
import useLoginNavigate, { SpaRoutes } from '../../../../../hooks/useLoginNavigate';
import useLoginContext from '../../../../../hooks/useLoginContext';
import { useKimEmailQuery } from './hooks/useKimEmailQuery';

const RegisterEmail = () => {
    const context = useLoginContext();
    const { register, handleSubmit, formState, watch, getValues, setValue } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const navigate = useLoginNavigate();
    const { data: kimEmail, isLoading: kimEmailIsLoading } = useKimEmailQuery();
    useEffect(() => {
        if (kimEmail) {
            setValue('email', kimEmail);
        }
    }, [kimEmail]);
    
    const checkEmailMutation = useMutation<boolean, ApiException, string>({
        mutationFn: accountApi.isEmailInUse,
        onSuccess: (isEmailInUse) => {
            if (isEmailInUse === true) {
                navigate(
                    SpaRoutes.CreateAccount.Private.BankId.EmailAlreadyConnected(
                        getValues('email'),
                    ),
                );
            } else {
                navigate(
                    SpaRoutes.CreateAccount.Private.BankId.EmailNotConnected(
                        getValues('email'),
                    ),
                );
            }
        },
    });

    const { givenName } = useExternalUser(context.authState);

    const handleStartFormSubmit = (formData: any) => {
        if (formState.isValid) {
            checkEmailMutation.mutate(formData.email);
        }
    };

    const emailForm = register('email', {
        required: 'Ange e-postadress',
        pattern: {
            value: regex.email,
            message: 'Angiven e-postadress har fel format.',
        },
    });
    const isLoading = checkEmailMutation.isPending || kimEmailIsLoading;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                {givenName ? `Hej ${givenName}!` : 'Logga in'}
            </h1>
            <p className="u-textCenter">
                När du skapar ett nytt konto behöver du koppla det till en e-postadress. Du loggar
                sedan in enbart med BankID.
            </p>
            <p className="u-textCenter">
                {kimEmail ? 'Stämmer följande e-postadress?' : 'Ange din e-postadress: '}
            </p>

            <form className="Form" onSubmit={handleSubmit(handleStartFormSubmit)}>
                {isLoading && <Loader className="u-marginTmd" />}

                {kimEmail && (
                    <FloatingLabel
                        defaultValue={kimEmail}
                        disabled={isLoading}
                        id="email"
                        className="u-marginTlg"
                        label="E-postadress"
                        autoFocus={true}
                        type="email"
                        formState={formState}
                        {...emailForm}
                    />
                )}
                {!kimEmailIsLoading && !kimEmail && (
                    <FloatingLabel
                        disabled={isLoading}
                        id="email"
                        className="u-marginTlg"
                        label="E-postadress"
                        autoFocus={true}
                        type="email"
                        formState={formState}
                        {...emailForm}
                    />
                )}
                
                <DotVomEmailWarning value={watch('email')} />
                <footer className="u-marginTauto">
                    {checkEmailMutation.isError && (
                        <p className="Notice Notice--red">
                            {checkEmailMutation.error.friendlyMessage}
                        </p>
                    )}

                    <button
                        className="Button Button--green u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading}
                    >
                        Gå vidare
                    </button>
                </footer>
            </form>
        </>
    );
};

export { RegisterEmail };
