import * as React from "react";
import { PunchoutCustomer } from "../../api/punchoutApi";
import { Wrapper, Row, Unit, Info } from "./style";
import Button from "../Button/Button";
interface Props {
    data?: PunchoutCustomer[];
    onClick: (id: string) => void;
}

const UserList = (props: Props) => {
    const { data } = props;

    return (
        <Wrapper>
            {data?.map((item, index) => (
                <Row key={item.id} fillBackground={index % 2 === 0}>
                    <Unit>
                        {item.organizationName}
                        <Info>
                            <div>
                                <strong>Adress</strong>: {item.address}
                            </div>
                            <div>
                                <strong>GLN</strong>: {item.gln}
                            </div>
                        </Info>
                    </Unit>
                    <Button
                        theme="inverted"
                        onClick={() => props.onClick(item.id)}
                        title="Logga in"
                    />
                </Row>
            ))}
        </Wrapper>
    );
};

export default UserList;
