import { useForm } from 'react-hook-form';
import useLoginContext from '../../hooks/useLoginContext';
import { SpaRoutes } from '../../hooks/useLoginNavigate';
import { FloatingLabel } from '../../components/FloatingLabel/FloatingLabel';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { Link } from 'react-router-dom';
import { ApiException } from '../../common/api/api.types';
import { useMutation } from '@tanstack/react-query';
import { IdentityProviderDto, accountApi } from '../../common/api/api.accounts';
import { regex } from '../../common/utils';
import { useEffect } from 'react';
import React from 'react';
import { AppContext } from '../App';

interface formLogin {
    Email: string;
    Password: string;
    RememberMe: boolean;
}
interface formEmail {
    ValidateEmail: string;
}
/*
https://loginlocal.coop.se:5001/logga-in?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dkommunal-admin-local%26redirect_uri%3Dhttp%253A%252F%252Flocalhost:3000%252Fcallback%26response_type%3Dcode%26scope%3Dopenid%2520hybris_api%2520hybris.profile%26state%3D129441ffd40445b8a50d4002d86b2734%26code_challenge%3DG_BHpd9XtCub5fyTy0i-UTPPCBKgDVT6-lVqSea3N1Q%26code_challenge_method%3DS256%26response_mode%3Dquery%26LoginType%3Dmunicipal
*/
const LoginOmbudshandel = () => {
    const context = React.useContext(AppContext);
    const [defaultEmail, setDefaultEmail] = React.useState<string>('');
    const [isEmailValidated, setIsEmailValidated] = React.useState(false);
    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);
    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                Logga in
                <br />
                ombudshandel
            </h1>
            {!isEmailValidated && (
                <SelectEmailStep
                    onEmailValidated={(email) => {
                        setIsEmailValidated(true);
                        setDefaultEmail(email);
                    }}
                    email={defaultEmail}
                />
            )}
            {isEmailValidated && (
                <LoginPasswordStep
                    onEmailChanged={(email) => {
                        setDefaultEmail(email);
                        setIsEmailValidated(false);
                    }}
                    email={defaultEmail}
                />
            )}
        </>
    );
};

const SelectEmailStep = (props: { email: string; onEmailValidated: (email: string) => void }) => {
    const { register, handleSubmit, formState, watch } = useForm<formEmail>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: false,
    });

    const context = useLoginContext();
    const email = watch('ValidateEmail');
    const returnUrl = context.authState.loginRequest?.returnUrl || context.authState.redirectUrl;

    useEffect(() => {
        if (email) {
            checkExternalProviderMutation.reset();
        }
    }, [email]);

    const checkExternalProviderMutation = useMutation<
        IdentityProviderDto | undefined,
        ApiException,
        {
            email: string;
        }
    >({
        mutationFn: (form) =>
            accountApi.getIdentityProviderForEmail({
                email: form.email,
            }),
        onSuccess: (data) => {
            if (!data) {
                props.onEmailValidated(email);
            }
        },
    });
    const handleEmailFormSubmit = (formData: formEmail) => {
        if (formState.isValid) {
            if (!hasValidatedExternalProvider) {
                checkExternalProviderMutation.mutate({ email: formData.ValidateEmail });
            }
        }
    };
    const isLoading = checkExternalProviderMutation.isPending;
    const hasExternalProvider = !!checkExternalProviderMutation.data;
    const hasValidatedExternalProvider = checkExternalProviderMutation.isSuccess;
    const errorCode = checkExternalProviderMutation.error?.code;
    return (
        <form className="Form" onSubmit={handleSubmit(handleEmailFormSubmit)}>
            <FloatingLabel
                id="validateEmail"
                className="u-marginTlg"
                label="E-postadress"
                type="email"
                disabled={isLoading}
                autoFocus={true}
                required
                autoComplete="off"
                formState={formState}
                defaultValue={props.email}
                {...register('ValidateEmail', {
                    required: 'Ange e-postadress',
                    pattern: {
                        value: regex.email,
                        message: 'Angiven e-postadress har fel format.',
                    },
                })}
            />

            <footer className="u-marginTauto">
                {errorCode == 'INVALID_EMAIL' && (
                    <p className="Notice Notice--red">
                        Felaktig e-postadress. Vänligen kontrollera att du har skrivit in den
                        korrekt och att det inte finns några mellanslag i e-postfältet.
                    </p>
                )}
                {!hasExternalProvider && (
                    <button
                        className="Button Button--green u-marginT"
                        disabled={!formState.isValid || isLoading}
                        type="submit"
                    >
                        Fortsätt
                    </button>
                )}
                {hasExternalProvider && (
                    <>
                        <p>
                            Din e-post {email} är kopplad till{' '}
                            {checkExternalProviderMutation.data?.name}.
                        </p>
                        <button
                            className="Button Button--green u-marginT"
                            disabled={!formState.isValid || isLoading}
                            type="button"
                            onClick={() => {
                                if (checkExternalProviderMutation.data?.scheme) {
                                    window.location.href = SpaRoutes.BuildChallengeExternalUrl(
                                        checkExternalProviderMutation.data?.scheme,
                                        returnUrl,
                                    );
                                }
                            }}
                        >
                            Logga in via {checkExternalProviderMutation.data?.name}
                        </button>
                    </>
                )}
            </footer>
        </form>
    );
};

const LoginPasswordStep = (props: { email: string; onEmailChanged: (email: string) => void }) => {
    const { register, handleSubmit, formState, watch } = useForm<formLogin>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: false,
    });
    const email = watch('Email');
    const context = useLoginContext();

    const returnUrl = context.authState.loginRequest?.returnUrl || context.authState.redirectUrl;

    const signInMutation = useMutation<
        void,
        ApiException,
        {
            email: string;
            password: string;
            rememberMe: boolean;
        }
    >({
        mutationFn: (props) =>
            accountApi.signinWithEmailPasswordToApplicationSchema({
                email: props.email,
                password: props.password,
                accountType: 'Both',
                rememberMe: props.rememberMe,
            }),
        onSuccess: () => {
            window.location.href = returnUrl;
        },
    });

    const handleUsernameFormSubmit = (formData: formLogin) => {
        if (formState.isValid) {
            signInMutation.mutate({
                email: formData.Email,
                password: formData.Password,
                rememberMe: formData.RememberMe,
            });
        }
    };
    console.log(email);
    const isLoading = signInMutation.isPending || signInMutation.isSuccess;
    return (
        <form className="Form" onSubmit={handleSubmit(handleUsernameFormSubmit)}>
            <FloatingLabel
                id="loginEmail"
                className="u-marginTlg"
                label="E-postadress"
                type="email"
                disabled={isLoading}
                autoFocus={true}
                autoComplete="off"
                formState={formState}
                defaultValue={props.email}
                {...register('Email', {
                    required: 'Ange e-postadress',
                    onChange: (e) => {
                        props.onEmailChanged(e.currentTarget.value);
                    },
                    pattern: {
                        value: regex.email,
                        message: 'Angiven e-postadress har fel format.',
                    },
                })}
            />
            <FloatingLabel
                id="loginPassword"
                className="u-marginTmd"
                label="Lösenord"
                type="password"
                autoComplete="off"
                formState={formState}
                disabled={isLoading}
                {...register('Password', { required: 'Ange lösenord' })}
            />

            <Checkbox
                id="loginRememberMe"
                className="u-marginT u-marginBz"
                label="Kom ihåg mig"
                formState={formState}
                disabled={isLoading}
                {...register('RememberMe')}
            />

            <footer className="u-marginTauto">
                {signInMutation.isError && (
                    <p className="Notice Notice--red">{signInMutation.error.friendlyMessage}</p>
                )}
                <button
                    className="Button Button--green u-marginT"
                    disabled={!formState.isValid || isLoading}
                    type="submit"
                >
                    Logga in
                </button>

                <p className="u-textCenter u-marginT u-marginBz u-textSmall">
                    <Link
                        className="Link"
                        to={{
                            pathname: '/glomt-losenord',
                            search: window.location.search,
                        }}
                    >
                        Glömt lösenord?
                    </Link>
                </p>
            </footer>
        </form>
    );
};

export default LoginOmbudshandel;
