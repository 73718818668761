import 'url-search-params-polyfill';
import '../../components/Form/Form.css';
import { isUserAuthenticatedWithBankid } from '../../core/authState.utils';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SpaRoutes } from '../../hooks/useLoginNavigate';
import useLoginContext from '../../hooks/useLoginContext';
import ChangeEmailWithEmail from './e-post/ChangeEmailWithEmail';
import ChangeEmailWithBankid from './bankid/ChangeEmailWithBankid';
import VerifyNewEmail from './verifiera/VerifyNewEmail';

const ChangeEmail = () => {
    const context = useLoginContext();
    return (
        <Routes>
            <Route path="bankid" element={<ChangeEmailWithBankid />} />
            <Route path="e-post" element={<ChangeEmailWithEmail />} />
            <Route path="verifiera/:email" element={<VerifyNewEmail />} />
            <Route
                path="*"
                element={
                    <Navigate
                        to={{
                            pathname: isUserAuthenticatedWithBankid(context.authState)
                                ? SpaRoutes.ChangeEmail.Bankid
                                : SpaRoutes.ChangeEmail.Email,
                            search: location.search,
                        }}
                        replace
                    />
                }
            />
        </Routes>
    );
};

export default ChangeEmail;
