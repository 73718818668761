import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    a {text-decoration: none;}
    
    .Pagination-page {
        font-size: 1em;
        color: #B6B4AD;
        padding: 0 10px;
        @media @bpXSmall {
            font-size: 1.4rem;
        }

        flex-shrink: 0;

        &:hover {
            color: #00A142;
            cursor: pointer;
        }

        &.is-active {
            color: #333333;
            font-weight: bold;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.has-truncationLess {
            margin-right: 10px;
        }

        &.has-truncationMore {
            margin-left: 10px;
        }
    }

    .Pagination-arrow {
        color: #B6B4AD;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid #B6B4AD;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }
}`;
