import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { ApiException } from '../common/api/api.types';

export const useFormApiErrors = <T extends FieldValues>(setError: UseFormSetError<T>) => {
    const handleErrors = (error: ApiException) => {
        if (error && error.field && error.friendlyMessage) {
            const field = error.field as Path<T>;
            setError(field, { message: error.friendlyMessage }, { shouldFocus: true });
        }
    };

    return { handleErrors };
};
