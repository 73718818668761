import { useQuery } from '@tanstack/react-query';
import { accountApi } from '../../../../../../common/api/api.accounts';
import queryKeys from '../../../../../../core/queryKeys';

const useKimEmailQuery = () => {
    const QUERY_KEY = queryKeys.kimEmail;
    const query = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const kimEmail = await accountApi.kimEmail();
            return kimEmail;
        },
    });
    return query;
};

export { useKimEmailQuery };
